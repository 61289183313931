const data = {
    menuItems: [
        {
        //     id: 1,
        //     label: "系统首页",
        //     icon: "el-icon-view",
        //     path: "/main/dashboard",
        //     children: null
        // }, {
            id: 2,
            label: "分时预约",
            icon: "el-icon-film",
            path: "/main/appoint",
            children: [
                {
                    id: 203,
                    label: "预约记录",
                    icon: "el-icon-view",
                    path: "/main/appoint/index",
                    children: null
                }, {
                    id: 204,
                    label: "电话预约",
                    icon: "el-icon-view",
                    path: "/main/appoint/form?s=电话预约",
                    children: null
                }, {
                    id: 205,
                    label: "VIP通道",
                    icon: "el-icon-view",
                    path: "/main/appoint/form?s=VIP通道",
                    children: null
                }, {
                    id: 206,
                    label: "现场录入",
                    icon: "el-icon-view",
                    path: "/main/appoint/scanIdCard",
                    children: null
                }
            ]
        }, {
            id: 16,
            label: "线上咨询",
            icon: "el-icon-film",
            path: "/main/consult",
            children: [
                {
                    id: 161,
                    label: "咨询记录",
                    icon: "el-icon-view",
                    path: "/main/consult/index",
                    children: null
                }
            ]
        }, {
            id: 12,
            label: "职工体检",
            icon: "el-icon-film",
            path: "/main/lianhua",
            children: [
                {
                    id: 121,
                    label: "炼化预约记录",
                    icon: "el-icon-view",
                    path: "/main/lianhua/index",
                    children: null
                // }, {
                //     id: 122,
                //     label: "炼化预约申请",
                //     icon: "el-icon-view",
                //     path: "/main/lianhua/form",
                //     children: null
                }, {
                    id: 123,
                    label: "石化预约",
                    icon: "el-icon-view",
                    path: "/main/shihua/index",
                    children: null
                }, {
                    id: 123,
                    label: "石化预约记录",
                    icon: "el-icon-view",
                    path: "/main/shihua/index",
                    children: null
                }, {
                    id: 124,
                    label: "石化固定套餐设置",
                    icon: "el-icon-view",
                    path: "/main/shihua/package",
                    children: null
                }, {
                    id: 125,
                    label: "石化自选套餐设置",
                    icon: "el-icon-view",
                    path: "/main/shihua/package-custom",
                    children: null
                }, {
                    id: 126,
                    label: "石化预约排期",
                    icon: "el-icon-view",
                    path: "/main/shihua/scheduling",
                    children: null
                }
            ]
        }, {
            id: 6,
            label: "门诊叫号",
            icon: "el-icon-film",
            path: "/main/queue",
            children: [
                {
                    id: 607,
                    label: "就诊队列",
                    icon: "el-icon-view",
                    path: "/main/queue",
                    children: null
                },
                {
                    id: 701,
                    label: "诊室设置",
                    icon: "el-icon-view",
                    path: "/main/queue/room",
                    children: null
                },
                {
                    id: 702,
                    label: "叫号屏幕",
                    icon: "el-icon-view",
                    path: "/main/queue/daping",
                    children: null
                }
            ]
        }, {
            id: 8,
            label: "门诊排班",
            icon: "el-icon-school",
            path: "/main/worksheet",
            children: [
                {
                    id: 801,
                    label: "日历排班",
                    icon: "el-icon-view",
                    path: "/main/worksheet/calendar",
                    children: null
                },
                {
                    id: 801,
                    label: "周期性排班",
                    icon: "el-icon-view",
                    path: "/main/worksheet",
                    children: null
                }
            ]
        }, {
            id: 10,
            label: "院前沟通",
            icon: "el-icon-notebook-1",
            path: "/main/record",
            children: [
                {
                    id: 1011,
                    label: "待沟通列表",
                    icon: "el-icon-view",
                    path: "/main/record/todo",
                    children: null
                }, {
                    id: 1012,
                    label: "已沟通列表",
                    icon: "el-icon-view",
                    path: "/main/record/index",
                    children: null
                }
            ]
        }, {
            id: 13,
            label: "微信设置",
            icon: "el-icon-chat-dot-round",
            path: "/main/wechat",
            children: [
                {
                    id: 132,
                    label: "体检须知",
                    path: "/main/wechat/notice",
                },
                {
                    id: 133,
                    label: "预约前提示",
                    path: "/main/wechat/alert",
                },
                {
                    id: 134,
                    label: "界面设置",
                    path: "/main/wechat/ui",
                },
                {
                    id: 131,
                    label: "二维码",
                    path: "/main/wechat/qrcode",
                },
            ]
        }, {
            id: 14,
            label: "统计报表",
            icon: "el-icon-data-line",
            path: "/main/statistics",
            children: null
        }, {
            id: 15,
            label: "系统设置",
            icon: "el-icon-setting",
            path: "/main/settings",
            children: [
                {
                    id: 1516,
                    label: "院区设置",
                    icon: "el-icon-view",
                    path: "/main/settings/hospital",
                    children: null
                }, {
                    id: 1513,
                    label: "类型设置",
                    path: "/main/settings/type",
                    children: null
                }, {
                    id: 1517,
                    label: "科室设置",
                    icon: "el-icon-view",
                    path: "/main/settings/department",
                    children: null
                }, {
                    id: 1518,
                    label: "医生设置",
                    icon: "el-icon-view",
                    path: "/main/settings/doctor",
                    children: null
                }, {
                    id: 1519,
                    label: "账号设置",
                    icon: "el-icon-view",
                    path: "/main/settings/users",
                    children: null
                }, {
                    id: 1520,
                    label: "菜单设置",
                    icon: "el-icon-view",
                    path: "/main/settings/menu",
                    children: null
                }]
        }, {
            id: 22,
            label: "系统日志",
            icon: "el-icon-notebook-1",
            path: "/main/oplog",
            children: null
        }
    ],
    filterArrForKey(arr, key, value) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterArrForKey(item.children, key, value)
                }
            }
            if (value.includes(item[key])) {
                return item
            } else {
                return null
            }
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    },
    filterChecked(arr, key) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterChecked(item.children)
                }
            }
            return item[key]
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    }
}
export default data
